import React, {useEffect, useRef, useState} from 'react';
import './App.css';

import {TabPanel} from "./common/TabPanel";
import {Box,  CssBaseline, Grid, Switch, Tab, Tabs} from "@mui/material";
// @ts-ignore
import gong from './audio/Schulgong.mp3';
// @ts-ignore
import pause from './audio/Pausenmusik-leise.mp3';
// @ts-ignore
import boylesTod from './audio/BoylesTod.mp3';
// @ts-ignore
import verhoerKurz from './audio/Mausefalle_Verhör1.Akt.kurz.mp3';
// @ts-ignore
import verhoerLang from './audio/Mausefalle_Verhör1.Akt.lang.mp3';
// @ts-ignore
import klavier from './audio/klavier.mp3';
// @ts-ignore
import telefonklingeln from './audio/Telefonklingeln.mp3';
// @ts-ignore
import radio from './audio/50-radio.mp3';

import {AudioPlayerHowler} from "./common/audio-player-howler";

const jingleVolume = 0.8
function App() {
    const verhoerRefParavicini = useRef<any>()
    const verhoerRefCasewell = useRef<any>()
    const verhoerRefMetcalf = useRef<any>()
    const verhoerRefBoyle = useRef<any>()
    const [tabValue, setTabValue] = useState(0)
    const [showControl, setShowControl] = useState<boolean>(false)
    let tabIndex = 0

    const onOutroPlay = (tmpRef: React.MutableRefObject<any>)=> {
        if (tmpRef.current) {
            tmpRef.current.stop()
            tmpRef.current.seek(0)
        }
    }

    return (
        <div className="App">
            <CssBaseline/>
            <Box className="sidebar" gridArea="sidebar">
                <Tabs orientation="vertical"
                      variant="scrollable"
                      value={tabValue}
                      onChange={(e, v) => setTabValue(v)}>
                    <Tab label="Alles"/>
                    <Tab label="Verhör"/>
                </Tabs>
            </Box>

            <Box className="main" gridArea="main" overflow="scroll">
                <TabPanel value={tabValue} index={tabIndex++}>
                    <Grid container direction="column">

                        <AudioPlayerHowler volume={1} audioSrc={gong} buttonLabel="Gong" />
                        <AudioPlayerHowler volume={1} audioSrc={pause} buttonLabel="Pause" />
                        <AudioPlayerHowler volume={1} audioSrc={boylesTod} buttonLabel="Boyles Tod" />
                        <AudioPlayerHowler volume={1} audioSrc={klavier} buttonLabel="Klavierspiel Mäuse" />
                        <AudioPlayerHowler volume={1} audioSrc={telefonklingeln} buttonLabel="Telefonklingeln" />
                        <AudioPlayerHowler volume={0.3} audioSrc={radio} buttonLabel="Radio" />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={tabIndex++}>

                    <Grid container direction="column">
                        <AudioPlayerHowler volume={1} audioSrc={verhoerKurz} buttonLabel="Verhör 1" />
                        <AudioPlayerHowler internalRef={verhoerRefParavicini} volume={1} audioSrc={verhoerLang} buttonLabel="Verhör Paravicini" />
                        <AudioPlayerHowler onPlay={() => onOutroPlay(verhoerRefParavicini)} internalRef={verhoerRefCasewell} volume={1} audioSrc={verhoerLang} buttonLabel="Verhör Casewell" />
                        <AudioPlayerHowler onPlay={() => onOutroPlay(verhoerRefCasewell)} internalRef={verhoerRefMetcalf} volume={1} audioSrc={verhoerLang} buttonLabel="Verhör Metcalf" />
                        <AudioPlayerHowler onPlay={() => onOutroPlay(verhoerRefMetcalf)} internalRef={verhoerRefBoyle} volume={1} audioSrc={verhoerLang} buttonLabel="Verhör Boyle" />
                        <AudioPlayerHowler onPlay={() => onOutroPlay(verhoerRefBoyle)} volume={1} audioSrc={verhoerKurz} buttonLabel="Verhör Letzte" />
                    </Grid>
                </TabPanel>

            </Box>
        </div>
    );
}

export default App;
